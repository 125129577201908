import React from "react";
import SvgIcon from "shared/svgs/helper/svg-icon";
import { HappyIcon, SadIcon } from "shared/svgs";
import { PersonalizedModule } from "flamingo-e-learning-platform/training/types/module.type";
import { useHistory } from "react-router-dom";
import { Button } from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";
import { useTranslation } from "react-i18next";
import { SurveySummaryResult } from "../../utils/survey-utils";

import styles from "./awareness-assessment-feedback.module.css";

interface AssessmentFailedProps {
  result: Array<SurveySummaryResult>;
  module: PersonalizedModule;
}

export const AwarenessAssessemntFailed = ({
  result,
  module,
}: AssessmentFailedProps) => {
  const history = useHistory();
  const { t } = useTranslation("awareness-assessment");

  const onGoToLesson = () => {
    history.push(`/elearning/${module.category_key}/${module.moduleKey}`);
  };

  return (
    <div className={styles.awarenessAssessmentFailed}>
      <h1>{t("youDidNotPassTheQuizz")}</h1>
      <div className={styles.questionFeedbackContainer}>
        {result.map((item, index) => (
          <div key={index}>
            <div className={styles.questionFeedback}>
              <div
                className={`${styles.answerIcon} ${item.passed ? styles.rightAnswerIcon : styles.wrongAnswerIcon
                  }`}
              >
                <SvgIcon Icon={item.passed ? HappyIcon : SadIcon} />
              </div>
              <div className={styles.questionFeedbackText}>
                <h3>{item.passed ? t("correct") : t("wrongAnswer")}</h3>
                <p>{item.text}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.footer}>
        <Button
          data-testid="go-to-lesson-button"
          aria-label={t("goToLesson")}
          onClick={onGoToLesson}
        >
          {t("goToLesson")}
        </Button>
      </div>
    </div>
  );
};
