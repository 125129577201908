import React from "react";
import { Button } from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import useInitTranslations from "lang/use-init-translations.hook";
import Loading from "shared/components/loading";
import Helmet from "shared/components/helmet";
import { AwarenessAssessmentSurvey } from "./components/awareness-assessment-survey";
import { mock } from "./mocks";
import style from "./awareness-assessment.page.module.css";
import translations from "./lang";

export const AwarenesssAssessmentPage = () => {
  const history = useHistory();
  const { translationsReady } = useInitTranslations(
    translations,
    "awareness-assessment"
  );

  const { t } = useTranslation(["awareness-assessment", "helmetTranslations"]);

  const onExit = () => {
    history.push("/");
  };

  if (!translationsReady) {
    return <Loading />;
  }

  return (
    <div className={style.container}>
      <Helmet title={t("helmetTranslations:awarenessAssessment")} />
      <div className={style.page}>
        <Button
          variant="outline"
          className={style.exitButton}
          aria-label="Exit Assessment"
          onClick={onExit}
        >
          {t("awareness-assessment:exitAssessment")}
        </Button>
        <AwarenessAssessmentSurvey
          surveyData={mock.surveyCheckboxMock}
          module={mock.personalizedModuleMock}
        />
      </div>
    </div>
  );
};
