import { AxiosInstance } from "axios";
import { URL_SURVEY_HUB_API } from "shared/helpers/url/url";
import { PostSurveyProps } from "survey-hub/components/survey/types/post-survey.type";
import { SurveyHubGetActiveSurveyResponse } from "types/survey-hub-types";

const getSurvey = async (axiosInstance: AxiosInstance, surveyId: number) => {
  try {
    const { data } = await axiosInstance.get(`${URL_SURVEY_HUB_API()}surveys/${surveyId}`);
    return data;
  } catch (error: any) {
    return error;
  }
};

const postSurvey = async (axiosInstance: AxiosInstance, props: PostSurveyProps) => {
  // Request type for answers to be changed in be-ms-survey-hub.
  const { userId, surveyId, answers } = props;
  try {
    const { data } = await axiosInstance.post(`${URL_SURVEY_HUB_API()}survey-results`, {
      userId,
      surveyId,
      answerData: answers,
    });
    // Add reference call to BE call, it is showing an error now for this successful request.
    // checkReference(data);
    return data?.result;
  } catch (error: any) {
    return error;
  }
};

interface GetSurveyResultProps {
  userId: number;
  surveyTypeId: number;
  surveyFeatureId: number;
}

const getSurveyResult = async (
  axiosIstance: AxiosInstance,
  { userId, surveyTypeId, surveyFeatureId }: GetSurveyResultProps
) =>
  axiosIstance.get(
    `${URL_SURVEY_HUB_API()}survey-results/?user_id=${userId}&survey_type_id=${surveyTypeId}&survey_feature_id=${surveyFeatureId}`
  );

export const getActiveSurvey = async (axiosInstance: AxiosInstance, surveyTypeId: number) =>
  axiosInstance.get<SurveyHubGetActiveSurveyResponse<any>>(
    `${URL_SURVEY_HUB_API()}surveys/${surveyTypeId}/active`
  );

export const surveyHubApi = (axiosInstance: AxiosInstance) => ({
  fetch: {
    getSurveyDataById: (surveyId: number) => getSurvey(axiosInstance, surveyId),
    getSurveyResult: (props: GetSurveyResultProps) => getSurveyResult(axiosInstance, props),
    getActiveSurvey: (surveyTypeId: number) => getActiveSurvey(axiosInstance, surveyTypeId),
  },
  post: {
    postSurveyAnswers: (props: PostSurveyProps) => postSurvey(axiosInstance, props),
  },
});
