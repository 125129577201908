import { Tooltip } from "@sosafe-platform-engineering/fe-lib-ui-react";
import React from "react";
import style from "./badge.module.css";

interface BadgeProps {
  url: string;
  name: string;
  onClick: () => void;
  alt: string;
}

export const Badge = ({ url, name, onClick, alt }: BadgeProps) => (
  <Tooltip content={name}>
    <button className={style.badge} onClick={onClick}>
      <img alt={alt} src={url} />
    </button>
  </Tooltip>
);
