import React from "react";
import { ClockIcon } from "@sosafe-platform-engineering/fe-lib-ui-react";
import { Badge, Card } from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";
import { useTranslation } from "react-i18next";
import { MODULE_STATUS } from "flamingo-e-learning-platform/training/types/module-status.type";
import { ModuleCardType } from "../module-card.types";

import style from "./library-module-card.module.css";

export const LibraryModuleCard = ({
  ActionButton,
  StatusTag,
  module,
}: ModuleCardType) => {
  const { name, duration } = module;
  const { t } = useTranslation("flamingo");

  const moduleCompleted = module.status === MODULE_STATUS.RESTART;
  const { mandatory } = module;

  const shouldShowMandatoryTag = !moduleCompleted && mandatory;
  const shouldShowOptionalTag = !moduleCompleted && !mandatory;

  return (
    <Card withBorder radius={"lg"} className={style.container}>
      <div className={style.body}>
        <h5 className={style.name}>{name}</h5>
        <div className={style.details}>
          <div className={style.detailsLeft}>
            <div className={style.duration}>
              <ClockIcon aria-label={t("averageDuration")} className={style.icon} />
              <small>
                {duration} {t("min")}
              </small>
            </div>
            <div className={style.tag}>

              {shouldShowMandatoryTag && (
                <Badge variant="warning" size="xs">
                  {t("mandatory")}
                </Badge>
              )}

              {shouldShowOptionalTag && (
                <Badge variant="optional" size="xs">
                  {t("optional")}
                </Badge>
              )}

              <StatusTag tagSize="xs" />

            </div>
          </div>
          <ActionButton />
        </div>
      </div>
    </Card>
  );
};
