import { getModuleKey } from "elearning/helpers";
import { useCategoriesRequest } from "elearning/hooks/use-categories-request";
import { PersonalizedModule } from "flamingo-e-learning-platform/training/types/module.type";
import { Dropdown } from "@sosafe-platform-engineering/fe-lib-ui-react";
import { Select } from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";
import { useTranslation } from "react-i18next";
import React, { useMemo, useState } from "react";
import { useHistory } from "react-router";
import { NoResults } from "flamingo-e-learning-platform/components/no-results/no-results";
import { MODULE_FILTER_OPTIONS } from "flamingo-e-learning-platform/training/types/module-filter-options";
import filterModules from "../shared/filter-modules";
import { ModuleFilter } from "../module-filter/module-filter";
import { sortMandatoryToFirst } from "../shared/sort-mandatory-module-to-first";
import { ModuleCardController } from "../module-card/module-card-controller";
import { LibraryModuleCard } from "../module-card/library-module-card/library-module-card";

import style from "./module-hub.module.css";

interface Props {
  personalizedModules: PersonalizedModule[];
}

export const ModuleHub = ({ personalizedModules }: Props) => {
  const history = useHistory();
  const { t } = useTranslation("flamingo");
  const { data: categoriesResponse } = useCategoriesRequest(true);

  const [selectedCategory, setSelectedCategory] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const [selectedModuleStatus, setSelectedModuleStatus] =
    useState<MODULE_FILTER_OPTIONS>(MODULE_FILTER_OPTIONS.ALL_LESSONS);

  const categories = categoriesResponse?.data.result || [];
  const mappedCategories = categories.map((category) => ({
    label: category.name,
    value: String(category.id),
  }));

  const onModuleCard = (module: PersonalizedModule) => {
    history.push(`/elearning/${module.category}/${getModuleKey(module)}`);
  };

  const filteredModulesArray = useMemo(() => {
    if (!selectedCategory) return null;
    const filteredModules = filterModules(
      personalizedModules,
      selectedCategory.value,
      selectedModuleStatus
    );
    return sortMandatoryToFirst(filteredModules);
  }, [personalizedModules, selectedCategory, selectedModuleStatus]);

  return (
    <div className={style.moduleHub}>
      <div className={style.divider}>
        <p className={style.dividerText}>
          <span>{t("divider")}</span>
        </p>
      </div>
      <div className={style.librarySection}>
        <div className={style.categoryDropdown}>
          <Select
            style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
            label={<h5 className={style.lessons}>{t("library")}</h5>}
            disabled={!mappedCategories}
            onChange={(selected) => {
              if (selected)
                setSelectedCategory({ label: selected, value: selected });
            }}
            placeholder={t("select_category")}
            name="modules-category"
            data-testid="modules-category"
            data={mappedCategories}
            value={selectedCategory ? selectedCategory.value : ""}
            aria-label={t("select_category")}
          />
        </div>
      </div>
      {selectedCategory && (
        <>
          <div className={style.categories}>
            <ModuleFilter
              onSelect={(filter) => setSelectedModuleStatus(filter)}
            />
          </div>
          {filteredModulesArray?.length ? (
            <div className={style.content}>
              {filteredModulesArray &&
                filteredModulesArray.map((mod) => (
                  <ModuleCardController
                    key={mod.id}
                    {...mod}
                    thumbnailUrl={undefined}
                    onAction={onModuleCard}
                    ModuleVariant={LibraryModuleCard}
                    buttonSize="xs"
                  />
                ))}
            </div>
          ) : (
            <NoResults />
          )}
        </>
      )}
    </div>
  );
};
