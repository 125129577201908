import { Modal } from "@sosafe-platform-engineering/fe-lib-ui-react";
import { Button } from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Shine } from "shared/svgs";
import style from "./badge-modal.module.css";

interface BadgeProps {
  name: string;
  image: string;
  description: string;
  received: string | null;
}

interface BadgeModalProps {
  onClose: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  badge: BadgeProps | null;
}

/**
 * Show a badge modal with image name and description.
 * includes 'Close' button translations
 *
 * @param {function} onClose
 * @param {object}   badge      Object with name, image and description keys
 *
 * @example { How to use }
 *  <BadgeModal
 *      onClose={()=> {setMyBadge(null)}}
 *      badge={myBadge}
 *  />
 *
 * @component
 * @category shared flamingo
 * @returns component
 */
export const BadgeModal = ({ onClose, badge }: BadgeModalProps) => {
  const { t } = useTranslation(["translations", "flamingo"]);

  return (
    <Modal className={style.badgeModal} onClose={onClose} isOpen={!!badge}>
      <Modal.Body>
        <div className={style.content}>
          <img
            alt={`${badge?.name} - ${badge?.received
              ? t("flamingo:badgeAchievedPrev")
              : t("flamingo:badgeNotAchievedPrev")
              }`}
            src={badge?.image}
          />
          <h2> {badge?.name}</h2>
          <p className={style.description}>{badge?.description}</p>
        </div>
        <Shine className={style.shine} />
      </Modal.Body>
      <Modal.Footer>
        <Button
          className={style.closeBtn}
          aria-label={t("translations:Close")}
          size="large"
          onClick={onClose}
        >
          {t("translations:Close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
