import {
  ChevronDownIcon,
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuSeparator,
  UserIcon,
  QuestionIcon,
  LifeBuoyIcon,
  LogOutIcon,
  MenuIcon,
  ElearningIcon,
  ShieldIcon,
  clsx,
} from "@sosafe-platform-engineering/fe-lib-ui-react";
import { Avatar } from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";
import { ACHIEVEMENT_PAGE_OPENED_FROM } from "elearning/helpers";
import React, { useCallback, useContext, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useMetaLinks from "shared/hooks/use-meta-links";
import { useHistory, useLocation } from "react-router";
import Logo from "shared/components/header/logo-header";
import { getDisplayName } from "shared/helpers";
import getShortName from "shared/helpers/get/get-short-name";
import GlobalSettingsContext from "shared/modules/global-settings/state/global-settings-context.provider";
import usePlatformSettings from "shared/hooks/use-platform-settings";

import useUser from "shared/hooks/use-user";
import useOutsideAlerter from "shared/hooks/use-outside-click";
import styles from "./header.module.css";

/**
 *
 * @category flamingo
 * @exports Header
 * @component
 */

type HeaderType = {
  handleSignOutClick: () => void;
  minimizedView: boolean;
};

type HeaderButtonType = {
  link: string;
  translation: string;
  redirect?: string;
  page: string;
};

type HeaderMenuButtonType = {
  page: string;
  translation: string;
  Icon?: any;
  iconColor?: "neutral" | "danger";
  areaLabel: string;
};

export default function Header(props: HeaderType) {
  const { user } = useUser({ enabled: false });
  const { t } = useTranslation("flamingo");
  const { getSupportLink, support } = useMetaLinks();
  const displayName = getDisplayName(user);
  const circleName = getShortName(user);
  const fallbackName = t("learner");
  const { handleSignOutClick, minimizedView } = props;
  const { globalSettings } = useContext(GlobalSettingsContext);
  const { settings: platformSettings } = usePlatformSettings();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const history = useHistory();

  const location = useLocation();
  const path = location.pathname;

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setIsMenuOpen);

  const isItemBold = (page: string, redirect?: string) => {
    if (path.includes(page) || path === redirect) {
      return styles.leftMenuItemActive;
    }
    return styles.leftMenuItem;
  };

  const onNavClickMap = new Map([
    ["training", () => history.push("/")],
    [
      "achievements",
      () =>
        history.push({
          pathname: "/achievements",
          state: {
            openedFrom: ACHIEVEMENT_PAGE_OPENED_FROM.START_PAGE.HEADER_MENU,
          },
        }),
    ],
    ["account", () => history.push("/account")],
    ["faq", () => history.push("/faq")],
    ["support", () => window.open(getSupportLink(), "_blank")],
    ["downloads", () => history.push("/downloads")],
    ["logout", () => handleSignOutClick()],
  ]);

  const onClickHandle = (type: string) => {
    onNavClickMap.get(type)?.();
  };

  const HeaderButton = useCallback(
    ({ link, translation, redirect, page }: HeaderButtonType) => (
      <Link
        data-testid={page}
        to={link}
        aria-label={translation}
        className={clsx(styles.dropBtn, isItemBold(page, redirect))}
      >
        {translation}
      </Link>
    ),
    [path]
  );

  const HeaderMenuDropdown = useCallback(
    ({
      page,
      translation,
      Icon,
      iconColor,
      areaLabel,
    }: HeaderMenuButtonType) => (
      <DropdownMenuItem
        text={translation}
        onClick={() => onClickHandle(page)}
        aria-label={translation}
        icon={Icon}
        iconColor={iconColor}
        area-label={areaLabel}
      />
    ),
    [path]
  );

  return (
    <header className={styles.header}>
      <div className={styles.wrapper}>
        <div className={styles.leftMenu}>
          <div className={styles.logo}>
            <Logo theme={globalSettings} />
          </div>
          {!minimizedView && (
            <nav
              className={styles.leftMenuNav}
              aria-labelledby={t("menu-label")}
            >
              <HeaderButton
                link="/"
                translation={t("training")}
                redirect="/"
                page="training"
              />
              {(platformSettings?.achievements ||
                platformSettings?.material_area) && (
                  <HeaderButton
                    link="/achievements"
                    translation={t("achievements")}
                    page="achievements"
                  />
                )}
              {platformSettings?.material_area && (
                <HeaderButton
                  link="/downloads"
                  translation={t("downloads")}
                  page="downloads"
                />
              )}
            </nav>
          )}
        </div>
        <div className={styles.rightMenu} ref={wrapperRef}>
          <div className={styles.rightAccount}>
            <button
              aria-label={t("openAccountDropdown")}
              className={styles.dropMenuBtn}
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              aria-expanded={isMenuOpen}
            >
              <Avatar
                alt={t("usersAvatar")}
                radius="xl"
                area-label={circleName || fallbackName.charAt(0)}
              >
                {circleName || fallbackName.charAt(0)}
              </Avatar>
              <p className={styles.name}>{displayName || fallbackName}</p>
              <ChevronDownIcon className={styles.chevIcon} />
            </button>
          </div>
          <div className={styles.mobileNav}>
            <MenuIcon
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className={styles.mobileNav}
              size="large"
            />
          </div>
          <DropdownMenu
            isOpen={isMenuOpen}
            className={styles.dropdownMenu}
            onClick={() => { }}
          >
            <DropdownMenuItem
              text={displayName || fallbackName}
              avatar={
                <Avatar
                  alt={t("usersAvatar")}
                  radius="xl"
                  area-label={circleName || fallbackName.charAt(0)}
                >
                  {circleName || fallbackName.charAt(0)}
                </Avatar>
              }
              subtext={user?.email || t("no_email")}
              area-label={circleName || fallbackName.charAt(0)}
            />
            <DropdownMenuSeparator />
            <div className={styles.mobileNav}>
              {!minimizedView && (
                <div>
                  <HeaderMenuDropdown
                    page="training"
                    translation={t("training")}
                    Icon={ElearningIcon}
                    areaLabel={t("training")}
                  />
                  {(platformSettings?.achievements ||
                    platformSettings?.material_area) && (
                      <HeaderMenuDropdown
                        page="achievements"
                        translation={t("achievements")}
                        Icon={ShieldIcon}
                        areaLabel={t("achievements")}
                      />
                    )}
                </div>
              )}
            </div>
            <HeaderMenuDropdown
              page="account"
              translation={t("personal_info")}
              Icon={UserIcon}
              areaLabel={t("personal_info")}
            />
            <HeaderMenuDropdown
              page="faq"
              translation={t("fag")}
              Icon={QuestionIcon}
              areaLabel={t("fag")}
            />
            {support && (
              <HeaderMenuDropdown
                page="support"
                translation={t("support")}
                Icon={LifeBuoyIcon}
                areaLabel={t("support")}
              />
            )}
            <DropdownMenuSeparator />
            <HeaderMenuDropdown
              page="logout"
              translation={t("logout")}
              Icon={LogOutIcon}
              iconColor="danger"
              areaLabel={t("logout")}
            />
          </DropdownMenu>
        </div>
      </div>
    </header>
  );
}
