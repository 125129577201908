import { useCategoriesRequest } from "elearning/hooks/use-categories-request";
import React, { useMemo } from "react";
import { CategoryProps, ModuleProps } from "types";
import { PersonalizedModule } from "../../types/module.type";
import { MODULE_STATUS } from "../../types/module-status.type";

const MODULE_STATUS_MAP = new Map<number, MODULE_STATUS>([
  [1, MODULE_STATUS.CONTINUE],
  [2, MODULE_STATUS.START],
  [3, MODULE_STATUS.RESTART],
]);

export const UsePersonalizedModule = () => {
  const { data, isLoading } = useCategoriesRequest(true);

  const flattenModules = useMemo((): Array<ModuleProps> => {
    if (!data?.data?.result) return [];
    return data.data.result.flatMap((category: CategoryProps) => [
      ...category.modules,
    ]);
  }, [data]);

  const personalizedModules = useMemo(
    (): Array<PersonalizedModule> =>
      flattenModules
        .map((mod) => ({
          ...mod,
          category: mod.category_key,
          id: mod.id,
          moduleKey: mod.module_name,
          thumbnailUrl: mod.image,
          status: MODULE_STATUS_MAP.get(mod.status ?? 2) ?? MODULE_STATUS.START,
        }))
        .sort((modA, modB) => modA.sort - modB.sort),
    [flattenModules]
  );

  return {
    isLoading,
    personalizedModules,
  };
};
