import React, { useState } from "react";
import ResponsivePicture from "shared/components/responsive-picture";
import { Tooltip } from "@sosafe-platform-engineering/fe-lib-ui-react";
import { BadgeProps } from "shared/modules/sosafe-connect/response-type";
import { BadgeModal } from "flamingo-e-learning-platform/components/badge-modal/badge-modal";
import { useTranslation } from "react-i18next";

import style from "./badge-button.module.css";

interface BadgeComponentProps {
  badge: BadgeProps;
  fromView: string;
  height: number;
}

/**
 * Component to display an achievement badge
 *
 * @category elearning
 * @subcategory components
 * @exports BadgeButton
 * @component
 */
const BadgeButton = ({ badge, height = 75 }: BadgeComponentProps) => {
  const { received } = badge;
  const [openBadge, setOpenBadge] = useState<BadgeProps | null>(null);
  const { t } = useTranslation("flamingo");

  return (
    <Tooltip content={badge.name}>
      <button
        aria-label={badge.name}
        onClick={() => setOpenBadge(badge)}
        className={style.button}
      >
        <ResponsivePicture
          grayscale={!received}
          src={badge.image}
          alt={`${badge?.name} - ${
            badge?.received ? t("badgeAchieved") : t("badgeNotAchieved")
          }`}
          fit="contain"
          defaultHeight={height}
        />
        <BadgeModal
          onClose={(event) => {
            event?.stopPropagation();
            setOpenBadge(null);
          }}
          badge={openBadge}
        />
      </button>
    </Tooltip>
  );
};

export default BadgeButton;
