import { ReactElement } from 'react';

export enum CustomButtonVariantType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SUCCESS = 'success',
  WARNING = 'warning',
  DANGER = 'danger',
  LIGHT = 'light',
  LINK = 'link',
  PRIMARY_OUTLINE = 'outline-primary',
  SECONDARY_OUTLINE = 'outline-secondary',
  SUCCESS_OUTLINE = 'outline-success',
  WARNING_OUTLINE = 'outline-warning',
  DANGER_OUTLINE = 'outline-danger',
  LIGHT_OUTLINE = 'outline-light',
  LINK_OUTLINE = 'outline-link',
}

export enum CustomButtonTooltipPosition {
  TOP = 'top',
  LEFT = 'left',
  BOTTOM = 'bottom',
  RIGHT = 'right',
}

type AriaHaspopup = boolean | 'dialog' | 'menu' | 'grid' | 'listbox' | 'tree' | 'true' | 'false';

export interface CustomButtonProps {
  /** Used as id and key */
  id?: string,

  /** Label of the button */
  label?: string | ReactElement;

  /** onClick */
  onClick(e: React.MouseEvent<HTMLElement, MouseEvent>): void,

  /** Variant to set appearance of the button */
  variant?: CustomButtonVariantType

  /** Additional classes */
  className?: string,

  /** Add tooltip to button */
  tooltip?: {
    /** Content of the tooltip. Can be string or React Element. */
    description: string | ReactElement,
    /** Desired position of the tooltip. Will change if there is not enough space */
    position: CustomButtonTooltipPosition
  },

  /** Button should be disabled */
  disabled?: boolean,

  /** Button will appear as submit button */
  isSubmitButton?: boolean,

  /** Button size */
  size?: 'sm' | 'lg',

  /** Will be set to label if undefined */
  ariaLabel?: string,

  /** used for special keyevents on listbox-popups */
  ariaHaspopup?: AriaHaspopup,

  /** Focus button on mount */
  focus?: boolean,

}
