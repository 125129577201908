// Temporarily disabled. See FP-664
// import * as chmln from '@chamaeleonidae/chmln';

// Get settings
const isChameleonActive = window.elearningRuntimeConfig.CHAMELEON_ACTIVE === 'true';
const chameleonToken = window.elearningRuntimeConfig.CHAMELEON_API;

/**
* Allow for subscription to events that will be subscribe by Chameleon custom triggers
* configurable from the Chameleon Builder
*/
// Temporarily disabled. See FP-664
// const chmlnRuntime = {
//   subscriptions: {},
//   subscribe: (id: number, event: () => void) => {
//     chmlnRuntime.subscriptions[id] = event;
//   },
//   triggerEventById: (id: number) => {
//     if (chmlnRuntime.subscriptions[id])
//       chmlnRuntime.subscriptions[id]();
//   }
// }

// Temporarily disabled. See FP-664
// // Do init?
// if (isChameleonActive) {
//   chmln.init(chameleonToken, { forceOverride: true });
//   window.chmlnRuntime = chmlnRuntime;
// }

// Only run actual Chameleon methods when activated
export default {
  isSupportedLanguage: (language: string) => ['en', 'de'].includes(language),
  identify: (...args): void => {
    // Temporarily disabled. See FP-664
    // if (isChameleonActive) chmln.identify(...args);
  },
  track: (...args): void => {
    // Temporarily disabled. See FP-664
    // if (isChameleonActive) chmln.track(...args);
  },
  clear: () => {
    // Temporarily disabled. See FP-664
    // if (isChameleonActive) {
    //   window.chmln?.clear();
    // }
  },
};
