import React from "react";
import AccountPage from "account/pages/account-page";
import DownloadsPage from "downloads/downloads.page";
import CategoryPage from "elearning/pages/category-page";
import ModulePage from "elearning/pages/module-page";
import { PlayerPage } from "elearning/pages/player-page";
import AchievementsPage from "gamification/pages/achievements-page";
import FaqPage from "support/pages/faq-page";
import Header from "shared/components/header/header";
import AppPageContent from "shared/components/app-page-content";
import OptinModal from "shared/components/onboarding/optin-modal";
import WelcomeModal from "shared/components/onboarding/welcome-modal";
import { useSoSafeConnect } from "shared/modules/sosafe-connect";
import useUser from "shared/hooks/use-user";
import useOnboarding from "shared/hooks/use-onboarding";
import { Survey } from "shared/modules/survey/components/survey";
import { SurveyProvider } from "shared/modules/survey";
import { useLocation } from "react-router-dom";
import showHeader from "shared/helpers/personalizedLearning/show-header";
import ErrorPage from "shared/pages/error/error.page";
import useAuthentication from "authentication/hooks/use-authentication";

export const routes = [
  {
    path: "/",
    render: () => <CategoryPage />,
  },
  {
    path: "/account",
    render: () => <AccountPage />,
  },
  {
    path: "/elearning/:categoryKey",
    render: () => <ModulePage />,
  },
  {
    path: "/elearning/:categoryKey/:moduleKey",
    render: () => <PlayerPage />,
  },
  {
    path: "/faq",
    render: () => <FaqPage />,
  },
  {
    path: "/achievements",
    render: () => <AchievementsPage />,
  },
  {
    path: "/downloads",
    render: () => <DownloadsPage />,
  },
  {
    path: "/503",
    render: () => <ErrorPage errorCode={503} />,
  },
];

export const defaultRoute = "/";

export const Wrapper = ({ children }: { children: JSX.Element }) => {
  const { signOut } = useAuthentication();
  const { pathname } = useLocation();
  const enableHeader = showHeader(pathname);
  useOnboarding();

  return (
    <React.Fragment>
      {enableHeader && (
        <Header handleSignOutClick={signOut} minimizedView={false} />
      )}
      <AppPageContent>{children}</AppPageContent>
      <SurveyProvider>
        <Survey />
      </SurveyProvider>
      <OptinModal />
      <WelcomeModal />
    </React.Fragment>
  );
};

export const UseIsPathAllowed = () => {
  const { apiKey } = useSoSafeConnect();
  const { user, loading } = useUser({});
  const isPersonalizedLearning = user?.campaign.isPersonalizedLearning ?? false;

  const loadingAuthorization = apiKey ? loading : false;

  return {
    allowed: !!apiKey && !isPersonalizedLearning,
    loading: loadingAuthorization,
  };
};
