import React from 'react';
import PoliciesPage from "policies/policies.page";
import { useSoSafeConnect } from 'shared/modules/sosafe-connect';
import useUser from 'shared/hooks/use-user';
import Header from 'shared/components/header/header';
import AccountPage from 'account/pages/account-page';
import FaqPage from 'support/pages/faq-page';
import AppPageContent from 'shared/components/app-page-content';
import useAuthentication from 'authentication/hooks/use-authentication';

export const routes = [
  {
    path: "/policies",
    render: () => <PoliciesPage />,
  },
  {
    path: "/account",
    render: () => <AccountPage />,
  },
  {
    path: "/faq",
    render: () => <FaqPage />,
  },
]

export const defaultRoute = "/policies";

export const Wrapper = ({ children }: { children: JSX.Element }) => {
  const { signOut } = useAuthentication();

  return <React.Fragment>
    <Header
      handleSignOutClick={signOut}
      minimizedView={true}
    />
    <AppPageContent>
      {children}
    </AppPageContent>
  </React.Fragment>
}

export const UseIsPathAllowed = () => {
  const { apiKey } = useSoSafeConnect();
  const { user, loading } = useUser({});
  const isPersonalizedLearning = user?.campaign.isPersonalizedLearning ?? false;

  const showPolicies = !!user?.game?.policies?.length;
  const loadingAuthorization = apiKey ? loading : false;

  return {
    allowed: !!apiKey && showPolicies && !isPersonalizedLearning,
    loading: !!apiKey && loadingAuthorization
  }
}
