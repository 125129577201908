import React from "react";
import Footer from "shared/components/footer";
import { useTranslation } from "react-i18next";
import useInitTranslations from "lang/use-init-translations.hook";
import { clsx } from "@sosafe-platform-engineering/fe-lib-ui-react";
import styles from "./content.page.module.css";
import translations from "../lang";

/**
 *
 * @category flamingo
 * @exports ContentPage
 * @component
 */

type ContentPageType = {
  children: JSX.Element | JSX.Element[];
};

export default function ContentPage(props: ContentPageType) {
  const { children } = props;
  const { translationsReady } = useInitTranslations(translations, "flamingo");
  const { t } = useTranslation(["helmetTranslations", "flamingo"]);

  if (!translationsReady) {
    return null;
  }

  return (
    <div
      className={clsx(styles.content, "personalised_learning")}
      id="personalised-learning-content"
    >
      <div className={styles.container}>{children}</div>
      <Footer hideOnLandscape={true} />
    </div>
  );
}
