import React, { useEffect } from "react";
import { ProductAnalytics } from "shared/modules/product-analytics";
import { ANALYTICS_TYPE } from "shared/modules/sosafe-connect";
import {
  ACHIEVEMENT_PAGE_OPENED_FROM,
  TRACKED_EVENTS,
} from "elearning/helpers";
import { useHistory } from "react-router";
import Helmet from "shared/components/helmet";
import { useTranslation } from "react-i18next";
import styles from "./achievements.page.module.css";
import LevelCard from "./components/level-card/level-card";
import ExperienceCard from "./components/experience/experience";
import ActivitiesCard from "./components/activities/activities";
import { BadgesCard } from "./components/badges/badgets";

/**
 *
 * @category flamingo
 * @exports AchievementsPage
 * @component
 */

export default function AchievementsPage() {
  const history = useHistory<{ openedFrom: string }>();
  const { t } = useTranslation("helmetTranslations");

  useEffect(() => {
    ProductAnalytics.getInstance().trackEvent(
      TRACKED_EVENTS.ACHIEVEMENT_PAGE_OPENED,
      {
        source:
          history.location.state?.openedFrom ||
          ACHIEVEMENT_PAGE_OPENED_FROM.DIRECT_ACCESS,
      }
    );
  }, [history.location.state?.openedFrom]);

  return (
    <div className={styles.container}>
      <Helmet title={t("Achievements")} />
      <div className={styles.box1}>
        <div className={styles.level}>
          <LevelCard />
        </div>
        <div className={styles.experience}>
          <ExperienceCard />
        </div>
      </div>
      <div className={styles.secondSection}>
        <div className={styles.box2}>
          <ActivitiesCard />
        </div>
        <div className={styles.box3}>
          <BadgesCard />
        </div>
      </div>
    </div>
  );
}
