import { Button } from "@sosafe-platform-engineering/fe-lib-ui-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { GameProgressLevelProps } from "types";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import AnimatedNumber from "../animated-number";
import Progressbar from "../progress-bar";
import { calculateProgress } from "../progress-bar/progressbar.utils";
import RewardShieldImage from "../reward-shield-image";
import { FeedbackError } from "../feedback";

interface NewLevelRewardProps {
  type: "xp" | "levelUp";
  currentLevel: GameProgressLevelProps;
  nextLevel?: GameProgressLevelProps;
  receivedXp: number;
  currentXp: number;
  onNext(): void;
}

type RewardType = "current" | "next";

export const NewLevelReward = ({
  currentLevel,
  nextLevel,
  currentXp,
  onNext,
  receivedXp,
  type,
}: NewLevelRewardProps) => {
  const { t, i18n } = useTranslation("translations");
  const [rewardType, setRewardType] = useState<RewardType>("current");

  const isCurrent = type === "xp" || rewardType === "current";

  const Reward = useCallback(() => {
    const level = isCurrent ? currentLevel : nextLevel;
    const title = isCurrent
      ? "Experience points received"
      : "New level reached";

    const currentXpBasedOnType = useMemo(() => {
      if (type === "xp") return currentXp;
      return isCurrent ? currentXp - receivedXp : currentXp;
    }, [type, currentXp, receivedXp]);

    if (!level) return <FeedbackError />;

    const progress = calculateProgress({
      minXp: level.xp,
      maxXp: level.xp_max,
      currentXp: currentXpBasedOnType,
      receivedXp,
    });

    // progress bar
    // the final percentage of current should always be 100%
    const endProgressBar =
      isCurrent && type === "levelUp" ? 100 : progress.percentageXp;

    // animated number
    // the current level xp can only ne as hight as the maxLevelXp
    const endAnimatedNumber = useMemo(() => {
      if (type === "xp") return progress.currentLevelXp;
      return isCurrent ? progress.maxLevelXp : progress.currentLevelXp;
    }, [isCurrent, progress]);

    const startAnimatedNumber = useMemo(() => {
      if (type === "xp") return progress.currentStartLevelXp;
      return isCurrent ? progress.currentLevelXp : 0;
    }, [isCurrent, progress]);

    return (
      <React.Fragment>
        <div>
          <h3 className="mb-2 h2 z-index-1">{`${t(title)}!`}</h3>
          <div className="position-relative d-flex">
            <RewardShieldImage image={level.image} />
          </div>
          <h4 className="mb-2 h1 text-secondary z-index-1">{level.name}</h4>
        </div>

        <div className="d-flex flex-column">
          <SwitchTransition>
            <CSSTransition
              in={true}
              timeout={300}
              classNames={{
                appear: "fade",
              }}
              unmountOnExit
            >
              <React.Fragment>
                {rewardType === "current" && (
                  <h4 className="h3 my-2">+{receivedXp} EP</h4>
                )}
                <Progressbar
                  className="mb-1"
                  progress={endProgressBar}
                  animate
                  startProgress={0}
                  delay={0.2}
                />
                <span className="mb-3">
                  <AnimatedNumber
                    start={startAnimatedNumber}
                    end={endAnimatedNumber}
                    duration={1}
                    delay={0}
                  />
                  /
                  <span data-testid="maxXpLevel">{progress.maxLevelXp} EP</span>
                </span>
              </React.Fragment>
            </CSSTransition>
          </SwitchTransition>

          <Button
            name="lets-go"
            className="mt-auto"
            ariaLabel={t("Great, let's go!")}
            onClick={onNext}
            disabled={isCurrent && type === "levelUp"}
          >
            {t("Great, let's go!")}
          </Button>
        </div>
      </React.Fragment>
    );
  }, [currentLevel, nextLevel, rewardType, currentXp]);

  useEffect(() => {
    if (type === "levelUp") {
      // change reward type from current to next after 2000 ms
      const timeout = setTimeout(() => {
        setRewardType("next");
      }, 2000);
      return () => clearTimeout(timeout);
    }
    return () => {
      /** */
    };
  }, [type]);

  return (
    <div
      className={`reward-modal-content ${i18n.language === "ar" ? "arab" : ""}`}
    >
      <Reward />
    </div>
  );
};
