import { useMutation } from "@tanstack/react-query"
import { queryClient, queryKey, useSoSafeConnect } from "shared/modules/sosafe-connect"
import { PostSurveyProps } from "survey-hub/components/survey/types/post-survey.type";


interface UsePostSurveyAnswersProps {
  onSucess: (result: PostSurveyProps) => void
  onError: <T = unknown>(error: T) => void
}

export const usePostSurveyAnswers = ({ onSucess, onError }: UsePostSurveyAnswersProps) => {

  const { endPoints } = useSoSafeConnect();

  return useMutation(
    (props: PostSurveyProps) => endPoints.surveyHubApi.post.postSurveyAnswers(props),
    {
      onSuccess: (response) => {
        onSucess(response);
      },
      onError: (error) => {
        onError(error);
      }
    }
  )
}
