import { AxiosInstance } from "axios";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import soSafeAxios from "state/axios";
import { surveyHubApi } from "survey-hub/api/survey-hub.api";
import { learningPathApi } from "personalized-learning/api/learning-path.api";
import { endPoints, EndpointsProps } from "../endpoints";
import { addSoSafeInterceptor } from "./axios-interceptors";
import { LoadingQueryScreen } from "../components/loading-query.screen";

interface SoSafeAxiosContextProps {
  apiKey: string;
  endPoints: EndpointsProps;
  axiosInstance: AxiosInstance;
  setApiKey(value: string): void;
  removeApiKey(): void;
  setShowLoadingScreen: Dispatch<SetStateAction<boolean>>;
}

const initialContext: SoSafeAxiosContextProps = {
  axiosInstance: soSafeAxios?.create(),
  endPoints: {
    ...endPoints(soSafeAxios),
    surveyHubApi: surveyHubApi(soSafeAxios),
    learningPathApi: learningPathApi(soSafeAxios),
  },
  setApiKey: () => console.warn("context not initialized"),
  removeApiKey: () => console.warn("context not initialized"),
  setShowLoadingScreen: () => console.warn("context not initialized"),
  apiKey: "",
};

const SoSafeAxiosContext =
  React.createContext<SoSafeAxiosContextProps>(initialContext);

/**
 * AxiosProvider initialize and pass down to the children the AxiosContext and all endpoints methods
 * making available to any children component thought the useSosafeAxios hook
 * @param param0
 * @returns
 */
export const SoSafeAxiosProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [apiKey, setKey] = useState<string>("");
  const [showLoadingScreen, setShowLoadingScreen] = useState(false);
  const axiosInstance = useRef(soSafeAxios.create());

  const setApiKey = useCallback(
    (value: string, localStorageOnly?: boolean) => {
      if (value) {
        localStorage.setItem("apikey", value);
        axiosInstance.current = soSafeAxios.create({
          headers: { Authorization: value },
        });

        addSoSafeInterceptor(
          axiosInstance.current,
          "interceptorHasAuthorization",
          () => {
            window.location.href = "";
          }
        );

        if (!localStorageOnly) setKey(value);
      } else {
        console.warn(
          "Warning: a undefined value was passed to set the apiKey, please pass a valid value"
        );
      }
    },
    [setKey]
  );

  const removeApiKey = useCallback(() => {
    localStorage.removeItem("apikey");
    setKey("");
  }, [setKey]);

  useEffect(() => {
    const key = localStorage.getItem("apikey");
    if (key) {
      setApiKey(key);
    }
  }, []);

  return (
    <SoSafeAxiosContext.Provider
      value={{
        apiKey,
        setApiKey,
        removeApiKey,
        setShowLoadingScreen,
        axiosInstance: axiosInstance.current,
        endPoints: {
          ...endPoints(axiosInstance.current),
          surveyHubApi: surveyHubApi(axiosInstance.current),
          learningPathApi: learningPathApi(axiosInstance.current),
        },
      }}
    >
      {children}
      {showLoadingScreen && <LoadingQueryScreen />}
    </SoSafeAxiosContext.Provider>
  );
};

/**
 * returns the AxiosContext
 * @returns SoSaveAxiosContext
 */
export const useSoSafeConnect = () => {
  const context = useContext(SoSafeAxiosContext);
  if (!context)
    throw new Error(
      "useSoSafeAxios can only be used inside a <SoSafeAxiosProvider/>"
    );
  return context;
};
