import React from "react";
import Loading from "shared/components/loading";
import { useTranslation } from "react-i18next";
import { Card } from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";
import useUser from "shared/hooks/use-user";
import { WelcomeCardBackground } from "./welcome-card-background";
import style from "./welcome-card.module.css";
import { UseWelcomeCardMessage } from "./welcome-card-messages/use-welcome-card-message";

export const WelcomeCard = () => {
  const { t } = useTranslation("flamingo");
  const { user } = useUser({ enabled: false });
  const welcomeText = user?.firstname
    ? `${t("welcome")} ${user?.firstname}!`
    : t("welcome-code-user");

  const MessageComponent = UseWelcomeCardMessage();

  if (!MessageComponent) {
    return <Loading />;
  }

  return (
    <Card withBorder radius={"lg"} className={style.welcomeCard}>
      <h5 className={style.heading}>{welcomeText}</h5>
      <p className={style.subHeading}>{t("welcome_header")}</p>
      <p className={style.subHeading}>
        <MessageComponent />
      </p>
      <div className={style.background}>{WelcomeCardBackground}</div>
    </Card>
  );
};
