import React, { useEffect, useRef, useState } from "react";
import Loading from "shared/components/loading";
import { useTranslation } from "react-i18next";
import { queryClient, queryKey } from "shared/modules/sosafe-connect";
import { UseReferenceHandler } from "shared/utilities/use-reference-handler";
import useElearningApi from "flamingo-e-learning-platform/hooks/use-elearning-api";
import chameleon from "shared/modules/chameleon";
import { TRACKED_EVENTS } from "elearning/helpers";
import { FEATURE_TYPE, SURVEY_TYPE } from "survey-hub/constants";
import styles from "./culture-survey-completed.module.css";

export default function CultureSurveyCompleted() {
  const { t } = useTranslation("survey_hub");
  const checkReference = UseReferenceHandler();

  const intervalRef = useRef<NodeJS.Timeout>();
  const [retryNumber, setRetryNumber] = useState(5);
  const eventEnabled =
    window.elearningRuntimeConfig.CHAMELEON_EVENT_ENABLED === "true";

  const onSuccess = (result) => {
    if (result.length > 0) {
      if (eventEnabled) {
        chameleon.track(TRACKED_EVENTS.SURVEY_COMPLETED);
      }
      queryClient.invalidateQueries([
        queryKey.SURVEY_HUB_RESULT,
        SURVEY_TYPE.CULTURE_AND_CONTEXT,
        FEATURE_TYPE.PERSONALIZED_LEARNING,
      ]);
      clearInterval(intervalRef.current);
    }

    if (result.length === 0) {
      setRetryNumber(retryNumber - 1);
      if (retryNumber === 0) {
        clearInterval(intervalRef.current);
      }
    }
  };

  const onError = (err) => {
    checkReference(
      err?.response?.data || {
        title: err.name,
        status: err.statusCode,
        reference: "get_error_reference_local",
      }
    );
  };
  const { refetch } = useElearningApi(onSuccess, onError, false);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      refetch();
    }, 3000);
    return () => clearInterval(intervalRef.current);
  }, []);

  return (
    <div className={styles.surveyCompleted}>
      <h2 className={styles.subHeading}>{t("completed_title")}</h2>
      <p className={styles.text}>{t("completed_description")}</p>
      <div className={styles.loading}>
        <Loading />
      </div>
    </div>
  );
}
