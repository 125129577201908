import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import useChameleon from "shared/hooks/app-hooks/use-chameleon";
import useItWasntMeConnection from "shared/hooks/app-hooks/use-it-wasnt-me-connection";
import useProductAnalytics from "shared/hooks/app-hooks/use-product-analytics";
import useSumologic from "shared/hooks/app-hooks/use-sumologic";
import useUser from "shared/hooks/use-user";
import { queryClient, queryKey } from "shared/modules/sosafe-connect";

/*
 * Provides an initial global configuration setup required by the app
 * eg: vendors, sync tabs and more
 * Add here anything that needs to be setup regardless of any state
 */
export const GlobalConfig = () => {
  const { user } = useUser({});
  const history = useHistory();
  const location = useLocation();
  const { i18n } = useTranslation("translations");

  useEffect(() => {
    queryClient.invalidateQueries([queryKey.ELEARNING]);
  }, []);

  useEffect(() => {
    if (!user || !user.language) return;
    // Set the user language in the local storage with the value returned by
    // authorization, therefore making sure the ui uses the right language.
    if (user.language !== i18n.language) {
      i18n.changeLanguage(user.language);
      localStorage.setItem("language", user.language);
    }
    window.document.documentElement.lang = user.language;
  }, [user?.language]);

  // Send data to Product Analytics
  useProductAnalytics(user);
  // Enabling Sumologic package for tracking in browser: TO-DO: Do we use this?
  useSumologic();
  // Chameleon integration
  useChameleon(user);
  // Constructor ItWasntMe connection: TO-DO: Do we even need it?
  useItWasntMeConnection(user, location, history);

  useEffect(() => {
    window.addEventListener("storage", (event) => {
      if (event.key === "apikey") {
        const hasHydratedApiKey = !event.oldValue && event.newValue;
        const hasDehydratedApiKey = event.oldValue && !event.newValue;
        if (hasHydratedApiKey || hasDehydratedApiKey) window.location.reload();
      }
    });
  }, []);

  return null;
}

