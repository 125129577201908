import React, { useEffect, useState } from "react";
import useUser from "shared/hooks/use-user";
import { useSurveyActive } from "survey-hub/hooks/use-survey-active";
import { useSurveyResult } from "survey-hub/hooks/use-survey-result";
import { queryClient, queryKey } from "shared/modules/sosafe-connect";
import { SURVEY_TYPE } from "survey-hub/constants";
import { usePutToRecalculateLP } from "./use-put-to-recalculate-lp";
import { useShouldGetLearningPath } from "./use-should-get-learning-path";
import { useIsRecalculationLPNeeded } from "./use-is-recalculation-lp-needed";

interface Props {
  isPersonalizedLearning: boolean;
  surveyTypeId: SURVEY_TYPE
  surveyFeatureId: number;
}

export const useShouldShowSurvey = ({ isPersonalizedLearning, surveyTypeId, surveyFeatureId }: Props) => {
  const { user } = useUser({});
  const [learningPathLoading, setLearningPathLoading] = useState(true);

  /* Check if user is PL and Survey Hub is enabled */

  const { data: activeSurveyResult, isLoading: isFetchingSurveyActive } =
    useSurveyActive({
      isSurveyEnabled: isPersonalizedLearning,
      surveyTypeId,
    });

  /* Check if user has already answered survey and if result is still loading */

  const { answeredSurveys, isFetchingSurveysAnswers } = useSurveyResult({
    userId: user?.id,
    surveyTypeId,
    surveyFeatureId,
    enabled: !!activeSurveyResult?.data.id,
  });

  /* Survey is answered, the check if training has been edited and check of need for the trigger of the learning path recalculation is enabled */

  const { isRecalculationNeeded, isRecalculationNeededLoading } =
    useIsRecalculationLPNeeded({
      userId: user?.id,
      enabled: !!answeredSurveys?.length,
    });

  /* When recalculation of the learning path is needed, triggering of it's recalculation is enabled,
    response from BE is expected, when the recalculation has been triggered successfully */

  const { isRecalulationTriggered } = usePutToRecalculateLP({
    userId: user?.id,
    enabled:
      !!answeredSurveys?.length &&
      !isRecalculationNeededLoading &&
      isRecalculationNeeded,
  });

  /* If recalculation of the learning path is needed and was triggered, call for updated data for learning path is to be processed
  The same logic as we have for survey completion is implemented here: getting learning path with an interval every 3 sec 5 times maximum */

  useShouldGetLearningPath({
    enabled:
      !!answeredSurveys?.length &&
      isRecalculationNeeded &&
      !learningPathLoading,
  });

  useEffect(() => {
    if (isRecalculationNeeded && isRecalulationTriggered) {
      setTimeout(() => {
        setLearningPathLoading(false);
        queryClient.invalidateQueries([
          queryKey.LEARNING_PATH_RECALCULATION_NEEDED,
        ]);
      }, 4000);
    }
  }, [isRecalulationTriggered, isRecalculationNeeded]);

  /* Only show survey for PL users and when survey is not answered and we are not waiting for fetch of survey results anymore */

  const showSurvey =
    isPersonalizedLearning &&
    !answeredSurveys?.length &&
    !isFetchingSurveysAnswers;

  /* Show generic loader if fetch of PL status is in process or survey results are in process
  or check for recalculation need is loading
  or response is there for recalculation need and recalculation trigger is loading
  or there is get updated learning path in loading
  */

  const loading =
    isFetchingSurveyActive ||
    isFetchingSurveysAnswers ||
    (isRecalculationNeeded && isRecalculationNeededLoading) ||
    (isRecalculationNeeded && !isRecalulationTriggered) ||
    (isRecalculationNeeded && learningPathLoading);

  return { showSurvey, loading };
};
