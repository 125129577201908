import { BadgeModal } from "flamingo-e-learning-platform/components/badge-modal/badge-modal";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { BadgeProps } from "types";
import { getModuleKey, TRACKED_EVENTS } from "elearning/helpers";

import { ProductAnalytics } from "shared/modules/product-analytics";
import Helmet from "shared/components/helmet";
import { useTranslation } from "react-i18next";
import { LearningPath } from "./components/learning-path/learning-path";
import { LevelCard } from "./components/level-card/level-card";
import { ModuleHub } from "./components/module-hub/module-hub";
import { SophieFacts } from "./components/sophie-facts/sophie-facts";
import { useLastBadges } from "./hooks/use-last-badges";
import { PersonalizedModule } from "./types/module.type";
import { UsePersonalizedModule } from "./hooks/use-personalized-module";
import { MobileView } from "./components/mobile-view/mobile-view";
import { WelcomeCard } from "./components/welcome-card/welcome-card";

import style from "./training.page.module.css";

/**
 * @category flamingo
 * @exports FlamingoTrainingPage
 * @component
 */
export default function FlamingoTrainingPage() {
  const [curBadge, setCurBadge] = useState<BadgeProps | null>(null);
  const history = useHistory();
  const { t } = useTranslation("helmetTranslations");

  const badgeComponents = useLastBadges({
    onClick: setCurBadge,
    maxNumberOfBadges: 3,
  });

  const onModuleCard = (module: PersonalizedModule) => {
    history.push(`/elearning/${module.category}/${getModuleKey(module)}`);
  };

  const { personalizedModules } = UsePersonalizedModule();

  const mandatoryModules = useMemo(
    () => personalizedModules.filter((mod) => mod.mandatory),
    [personalizedModules]
  );

  useEffect(() => {
    ProductAnalytics.getInstance().trackEvent(TRACKED_EVENTS.START_PAGE_VIEWED);
  }, []);

  return (
    <div className={style.container}>
      <Helmet title={t("training")} />
      <div className={style.widgets}>
        <WelcomeCard />
        <LevelCard
          badges={badgeComponents}
          OnLearnMore={() => history.push("/achievements")}
        />
        <BadgeModal onClose={() => setCurBadge(null)} badge={curBadge} />
        <SophieFacts />
      </div>
      <div className={style.trainingModules}>
        <LearningPath
          onModuleCard={onModuleCard}
          mandatoryModules={mandatoryModules}
        />
        <ModuleHub personalizedModules={personalizedModules} />
      </div>
      <div className={style.mobileView}>
        <MobileView
          onModuleCard={onModuleCard}
          mandatoryModules={mandatoryModules}
        />
      </div>
    </div>
  );
}
