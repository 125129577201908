import React from "react";
import {
  IconCircleArrowLeftFilled,
  IconCircleArrowRightFilled,
} from "@sosafe-platform-engineering/fe-lib-ui-mantine-react/icons";
import { Carousel } from "@sosafe-platform-engineering/fe-lib-ui-mantine-react/carousel";
// this should be imported from fe-lib-ui-mantine-react but it's not being
// exported from there yet
import '@mantine/carousel/styles.css';
import style from "./carousel.module.css";

type CarouselComponentType = { components: JSX.Element[]; slides: number };
export const CarouselComponent = (props: CarouselComponentType) => (
  <div className={style.card}>
    <Carousel
      slidesToScroll={props.slides}
      align="start"
      slideSize={`${100 / props.slides}%`}
      className={style.container}
      loop
      nextControlIcon={
        <IconCircleArrowRightFilled className={style.icon} stroke={2} />
      }
      previousControlIcon={
        <IconCircleArrowLeftFilled className={style.icon} stroke={2} />
      }
      dragFree
      withIndicators
      classNames={{
        root: style.mantineRoot,
        controls: style.mantineControls,
        control: style.mantineControl,
      }}
    >
      {props.components.map((element, key) => (
        <Carousel.Slide className={style.slide} key={`slide-${key}`}>
          {element}
        </Carousel.Slide>
      ))}
    </Carousel>
  </div>
);
