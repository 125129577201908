import React, { useEffect, useMemo, useState } from "react";

import {
  ArrowRightIcon,
  CheckCircleIcon,
  VeraAnnotationXIcon,
  Tag,
} from "@sosafe-platform-engineering/fe-lib-ui-react";

import {
  Card,
  Button,
  Progress,
} from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";

import Loading from "shared/components/loading";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import { PersonalizedModule } from "flamingo-e-learning-platform/training/types/module.type";
import { TagProps } from "@sosafe-platform-engineering/fe-lib-ui-react/dist/components/tag";
import { useTranslation } from "react-i18next";
import {
  addCustomItems,
  attachSoSafeSurveyDefaults,
  customCssSurvey,
  getSurveySummaryResult,
  sanitizeSurveyModel,
  SurveySummaryResult,
  isSurveyPassed,
} from "../../utils/survey-utils";
import { registerSoSafeCheckboxItem } from "../survey-custom-elements/survey-checkbox";
import "../sosafe-survey-style.css";
import { AwarenessAssessmentFeedback } from "../awareness-assessment-feedback";
import style from "./awareness-assessment-survey.module.css";

interface AwarenessAssessmentSurveyProps {
  surveyData: any;
  module: PersonalizedModule;
}

export const AwarenessAssessmentSurvey = ({
  surveyData,
  module,
}: AwarenessAssessmentSurveyProps) => {
  const [survey, setSurvey] = useState<Model>();
  const [isNextDisabled, setIsNextDisabled] = useState(true);
  const { t } = useTranslation("awareness-assessment");
  const [surveyResult, setSurveyResult] =
    useState<Array<SurveySummaryResult> | null>(null);

  const sosafeSurvey = useMemo(() => {
    const sanitizedSurvey = sanitizeSurveyModel(surveyData);
    const surveyWithCustomItems = addCustomItems(sanitizedSurvey);
    const surveyWithSoSafeDefaults = attachSoSafeSurveyDefaults(
      surveyWithCustomItems
    );
    return surveyWithSoSafeDefaults;
  }, []);

  useEffect(() => {
    registerSoSafeCheckboxItem();

    const surveyModel = new Model(sosafeSurvey);
    surveyModel.css = customCssSurvey();

    setSurvey(surveyModel);
  }, [sosafeSurvey]);

  const onNext = () => {
    if (survey && survey.currentPageNo === survey.pageCount - 1) {
      survey?.doComplete();
      return;
    }
    survey?.nextPage();
  };

  const onComplete = () => {
    if (!survey) return;
    const result = getSurveySummaryResult(survey);
    setSurveyResult(result);
  };

  const onCurrentPageChanged = () => {
    setIsNextDisabled(true);
  };

  const onValueChanged = () => {
    if (survey) {
      const answer = survey.getValue(survey.activePage.questions[0].name);
      setIsNextDisabled(!answer?.length);
    }
  };

  const tagProps = useMemo((): Pick<TagProps, "variant" | "icon"> => {
    if (surveyResult === null)
      return { icon: ArrowRightIcon, variant: undefined };
    const passed = isSurveyPassed(surveyResult);
    if (passed) return { icon: CheckCircleIcon, variant: "success" };
    return { icon: VeraAnnotationXIcon, variant: "error" };
  }, [surveyResult]);

  if (!survey) return <Loading />;

  return (
    <div>
      <Card withBorder radius={"lg"} className={`${style.container}`}>
        <div className={`${style.header}`}>
          <Tag
            className={style.tag}
            variant={tagProps.variant}
            content={`${t("lesson")}: ${module.name}`}
            icon={tagProps.icon}
          />
          {!surveyResult && (
            <div className={`${style.progressbarContainer}`}>
              <Progress
                role="progressbar"
                aria-valuenow={survey.currentPageNo}
                aria-valuemin={1}
                aria-valuemax={survey.pageCount}
                aria-labelledby="survey-progress"
                className={`${style.progressbar}`}
                value={Math.floor((survey.currentPageNo / survey.pageCount) * 100)}
              />
              <label id="survey-progress">
                <span className="sr-only">{t("step")}</span>
                <span>{`${survey.currentPageNo + 1} ${t(
                  "outOf"
                )} ${survey?.pageCount}`}</span>
                <span className="sr-only">{t("steps")}</span>
              </label>
            </div>
          )}
        </div>
        {!surveyResult && (
          <Survey
            model={survey}
            onCurrentPageChanged={onCurrentPageChanged}
            onValueChanged={onValueChanged}
            onComplete={onComplete}
          />
        )}
        {surveyResult && (
          <AwarenessAssessmentFeedback module={module} result={surveyResult} />
        )}
        {!surveyResult && (
          <div className={style.footer}>
            <Button
              data-testid="next-button"
              disabled={isNextDisabled}
              className={style.nextButton}
              aria-label={t("next")}
              onClick={onNext}
            >
              {t("next")}
            </Button>
          </div>
        )}
      </Card>
    </div>
  );
};
