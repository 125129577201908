import React from "react";
import {
  Card,
  Progress,
} from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";
import { useTranslation } from "react-i18next";
import { useUserProgress } from "shared/hooks/use-user-progress";
import { CertificateModal } from "shared/components/personalised-learning/certificate-modal";
import { UseWelcomeCardMessage } from "flamingo-e-learning-platform/training/components/welcome-card/welcome-card-messages/use-welcome-card-message";
import FLAGS from "../../../training/components/shared/check-for-flags";
import style from "./level-card.module.css";

interface LevelCardProps {
  onCertificate?: () => void;
}

const LevelCard = ({ onCertificate }: LevelCardProps) => {
  const { t } = useTranslation("flamingo");
  const { levelImage, levelName, progressBar } = useUserProgress();

  const MessageComponent = UseWelcomeCardMessage();

  return (
    <Card withBorder radius={"lg"} className={style.levelCard}>
      <div className={style.levelCardBody}>
        <div className={style.levelBadge}>
          <img alt={t("level")} src={levelImage} />
        </div>
        <div className={style.info}>
          <div className={style.progressBar}>
            <div className={style.progressBarHeader}>
              <h5>{levelName}</h5>
            </div>
            <Progress
              value={Math.floor(progressBar.progress)}
              role="progressbar"
              aria-valuenow={progressBar.curXp}
              aria-valuemin={0}
              aria-valuemax={progressBar.totalXp}
              aria-labelledby="progress-text"
            />
            <label className={style.progreeBarLevelXp}>
              <strong>{progressBar.curXp}</strong>
              <span className="sr-only">{t("experiencePoints")}</span>
              <span>{t("out_of")}</span>
              <strong>{progressBar.totalXp}</strong>
              <span className="sr-only" >{t("experiencePoints")}</span>
            </label>
          </div>
          {!!MessageComponent && <MessageComponent />}
          {FLAGS.isCertificateActivated && (
              <CertificateModal />
          )}
        </div>
      </div>
    </Card>
  );
};

export default LevelCard;
