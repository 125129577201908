import React from 'react';

export const WelcomeCardBackground = <svg width="296" height="133" viewBox="0 0 296 133" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g opacity="0.8">
    <path d="M174.672 22.7238L155.997 15.7324" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M159.031 29.5904L139.471 23.0809" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M214.227 184.941L234.237 128.969C234.237 128.969 242.943 127.348 251.424 110.746C259.905 94.1418 274.991 57.9054 267.483 52.9726C260.238 48.2045 255.367 58.1568 253.432 62.4932C251.498 66.8296 244.565 80.5579 244.565 80.5579C244.565 80.5579 254.547 45.6404 256.315 38.4129C258.285 30.4082 256.127 26.168 252.311 25.133C247.793 23.9286 244.732 28.5895 243.193 32.5975C241.657 36.6049 229.601 69.9459 229.601 69.9459C229.601 69.9459 238.675 31.3962 240.302 21.9176C241.926 12.4427 237.028 9.74907 234.786 9.34711C232.539 8.94269 228.374 9.54566 226.053 15.3453C223.963 20.5146 213.483 61.8756 213.483 61.8756C213.483 61.8756 218.319 26.8689 218.423 19.5515C218.527 12.234 212.807 9.40205 208.318 11.6085C203.378 14.0342 201.378 25.7127 199.795 32.2541C198.216 38.7949 190.748 66.4269 189.966 69.3261C189.183 72.2252 186.892 75.8332 183.524 73.1984C180.159 70.5599 172.719 60.4734 163.673 58.4412C154.619 56.3778 155.161 63.7492 155.685 65.1829C157.166 69.2517 170.438 97.7595 171.905 102.17C173.375 106.58 175.202 111.041 173.648 116.469C172.094 121.897 157.102 164.466 157.102 164.466L214.227 184.941Z" strokeWidth="6" strokeLinejoin="round" />
    <path d="M227.273 104.604C203.027 120.893 149.203 100.415 116.067 80.6357C79.2136 58.606 78.0019 35.5677 92.8712 35.8026C113.562 36.1112 163.751 98.0872 133.767 111.041C109.036 121.823 64.505 101.267 52.7046 113.678C40.9065 126.088 35.4094 153.859 3.00254 167.665" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M60.0369 87.9451C54.8236 87.9451 50.5969 83.8885 50.5969 78.8849C50.5969 73.8779 54.8236 69.8213 60.0369 69.8213C65.2502 69.8213 69.4805 73.8779 69.4805 78.8849C69.4805 83.8885 65.2502 87.9451 60.0369 87.9451Z" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" />
  </g>
</svg>
