import React from "react";
import LoginPage from "authentication/pages/login-page";
import RegisterConfPage from "authentication/pages/register-conf-page";
import RegisterGetDataPage from "authentication/pages/register-get-data-page";
import SamlPage from "authentication/pages/saml-page";
import SetPasswordPage from "authentication/pages/set-password-page";
import RequestPasswordPage from "authentication/pages/request-password-page";
import AppPageContent from "shared/components/app-page-content";
import { useSoSafeConnect } from "shared/modules/sosafe-connect";
import { RouterWithAuthorizationMethod } from "router-controller/router-with-authorization";
import { AuthContextProps } from "auth-context/auth-context";
import ErrorPage from "shared/pages/error/error.page";
import TimeoutPage from "support/pages/timeout-page";

export const routes = [
  {
    path: "/",
    render: (p) => (
      <RouterWithAuthorizationMethod
        Component={(auth: AuthContextProps) => (
          <LoginPage
            location={p.location}
            doSignUp={auth.signUp}
            doSignIn={auth.signIn}
          />
        )}
      />
    ),
  },
  {
    path: "/register",
    render: () => (
      <RouterWithAuthorizationMethod
        Component={(auth: AuthContextProps) => (
          <RegisterGetDataPage doSignUp={auth.signUp} />
        )}
      />
    ),
  },
  {
    path: "/registration",
    render: () => (
      <RouterWithAuthorizationMethod
        Component={(auth: AuthContextProps) => (
          <RegisterGetDataPage doSignUp={auth.signUp} />
        )}
      />
    ),
  },
  {
    path: "/register/conf",
    render: () => <RegisterConfPage />,
  },
  {
    path: "/password",
    render: (p) => <RequestPasswordPage location={p.location} />,
  },
  {
    path: "/setpassword/:token",
    render: () => <SetPasswordPage />,
  },
  {
    path: "/saml",
    render: () => <SamlPage />,
  },
  {
    path: "/503",
    render: () => <ErrorPage errorCode={503} />,
  },
];

export const defaultRoute = "/";

export const Wrapper = ({ children }: { children: JSX.Element }) => (
  <React.Fragment>
    <AppPageContent>{children}</AppPageContent>
  </React.Fragment>
);

export const UseIsPathAllowed = () => {
  const apiKey = localStorage.getItem("apikey");
  return {
    allowed: !apiKey,
    loading: false,
  };
};
