import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Provider } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { clsx } from "@sosafe-platform-engineering/fe-lib-ui-react";

import RewardModal from "shared/components/reward-modal";

import { store } from "state/store/store";

import { MantineProvider } from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";
import { LevelProgressProvider } from "../level-progress";
import {
  queryClient,
  QueryContextProvider,
  queryKey,
  SoSafeAxiosProvider,
} from "../sosafe-connect";
import { useNotificationSystemContext } from "./notification-system-context";

const MySwal = withReactContent(Swal);

const NotificationSystem = () => {
  const {
    notifications,
    isVisible,
    setNotificationVisible,
    clearNotificationQueue,
    notificationCallback,
  } = useNotificationSystemContext();

  const { i18n } = useTranslation("translations");

  useEffect(() => {
    let timeout;

    const showNotifications = async () => {
      let index = 0;

      // Notification Feedback is always the first modal to be displayed
      const orderedNotificationsToDisplay =
        notifications?.current?.sort(
          (current, next) => current?.displayOrder - next?.displayOrder
        ) || [];
      for (const notification of orderedNotificationsToDisplay) {
        await MySwal.fire({
          html: (
            <QueryContextProvider>
              <MantineProvider>
                <SoSafeAxiosProvider>
                  <Provider store={store}>
                    <LevelProgressProvider>
                      <RewardModal
                        notificationToShow={notification}
                        totalNotification={notifications?.current?.length}
                      />
                    </LevelProgressProvider>
                  </Provider>
                </SoSafeAxiosProvider>
              </MantineProvider>
            </QueryContextProvider>
          ),

          buttonsStyling: false,
          showConfirmButton: false,
          customClass: {
            popup: clsx({ arab: i18n.language === "ar " }, "w-auto"),
            closeButton: "reward-modal-close",
          },
          showCloseButton: true,
          closeButtonAriaLabel: i18n.t("Close"),
          focusConfirm: false,
          willClose: () => {
            // whenever we get badges or level the activities are update. invalidating the query makes sure we fetch the latest information when needed.
            queryClient.invalidateQueries([queryKey.ACTIVITIES]);
            queryClient.invalidateQueries([queryKey.BADGES]);
          },
        }).then(() => {
          if (index >= (notifications?.current?.length || 0) - 1) {
            notificationCallback?.current?.();
            clearNotificationQueue();
          }

          setNotificationVisible(false);
        });

        index++;
      }
    };

    if (isVisible) {
      timeout = setTimeout(() => {
        showNotifications();
      }, 300);
    }

    return () => clearInterval(timeout);
  }, [isVisible]);

  return <React.Fragment />;
};

export default NotificationSystem;
