import React from "react";
import { FactType } from "flamingo-e-learning-platform/training/types/fact.type";
import { useDidYouKnowHintRequest } from "elearning/hooks/use-did-you-know-hint-request";
import { FactsCard } from "./facts";
import SophieSVG from "../../../svgs/sophie.svg";

import style from "./sophie-facts.module.css";

export const SophieFacts = () => {
  let facts: FactType[] | undefined;
  const { data } = useDidYouKnowHintRequest({
    enabled: !facts,
  });

  if (data) {
    facts = data.data.result;
  }

  if (!facts || (facts && facts.length === 0)) {
    return null;
  }

  return (
    <div className={style.sophieContent}>
      <SophieSVG className={style.sophieSVG} />
      <FactsCard facts={facts} />
    </div>
  );
};
