import React from "react";
import { ConfiguringSVG } from "shared/svgs";
import { useTranslation } from "react-i18next";
import SurveyHubTemplate from "./components/survey-hub.template";
import SurveyWelcome from "./components/survey/survey-welcome";
import { SURVEY_TYPE } from "./constants";
import HRSSurveyCompleted from "./components/survey/hrs-survey/hrs-survey-completed";

const CultureSurveyWelcome = ({ onStart }: { onStart: () => void }) => {
  const { t } = useTranslation("survey_hub");

  return (
    <SurveyWelcome
      onStart={onStart}
      title={t("hrs_welcome_title")}
      description={t("hrs_welcome_description")}
      SVG={ConfiguringSVG}
      startButtonText={t("start")}
    />
  );
};

/**
 * HOC component which wraps a Culture Survey
 * @returns
 */
const HRSSurveyHubPage = () => (
  <SurveyHubTemplate
    WelcomeComponent={CultureSurveyWelcome}
    surveyType={SURVEY_TYPE.HRS_SELF_RATING}
    CompletedComponent={HRSSurveyCompleted}
  />
);

export default HRSSurveyHubPage;
