/**
 * This component replaces the component of surveyJS with our custom one
 */
import React from "react";
import { ReactElementFactory, ReactSurveyElement } from "survey-react-ui";
import { useTranslation } from "react-i18next";
import styles from "./radio-button.module.css";

export const SOSAFE_RADIO_BUTTON = "sosafe-radio-button";

class RadioButton extends ReactSurveyElement {
  renderElement() {
    const { question, item } = this.props;
    const itemId = question.getItemId(item);

    // The component changes based on whether it is needs to display more that 2 items or not
    const isSmallContainer = question.choices.length <= 2;

    const handleOnChange = () => {
      question.value = item.value;
    };

    const handleKeyDown = (event: { key: string; shiftKey: any }) => {
      switch (event.key) {
        case "Enter":
          handleOnChange();
          break;
        case "Tab":
          if (event.shiftKey) {
            const focusableElements = Array.from(
              document.querySelectorAll<
                | HTMLInputElement
                | HTMLTextAreaElement
                | HTMLButtonElement
                | HTMLSelectElement
              >('input[type="radio"], textarea, button, select')
            );
            const currentIndex = focusableElements.findIndex(
              (element) => element === document.activeElement
            );
            if (currentIndex > 0) {
              focusableElements[currentIndex - 1].focus();
            } else {
              // If currentIndex is 0, focus on the last element
              focusableElements[focusableElements.length - 1].focus();
            }
          } else {
            // Tab pressed, focus on the next element
            document.getElementById(itemId)?.focus();
          }
          break;
        default:
          break;
      }
    };

    // Use the useTranslation hook
    const { t } = this.props;

    return (
      <div
        className={
          isSmallContainer ? styles.smallContainer : styles.largeContainer
        }
        role="presentation"
      >
        <button
          tabIndex={0}
          onKeyDown={handleKeyDown}
          onClick={handleOnChange}
          aria-label={`${t("answer")}: ${item.text}. ${t("by_click")}`}
          name={`${t("answer")}: ${item.text}. ${t("by_click")}`}
        >
          <input
            id={itemId}
            type="radio"
            name={question.questionName}
            aria-label={`${t("answer")}: ${item.text}. ${t("by_click")}`}
            value={item.value}
            onChange={handleOnChange}
            checked={question.value === item.value}
          />
          {isSmallContainer ? (
            item.text
          ) : (
            <label htmlFor={itemId}>{item.text}</label>
          )}
        </button>
      </div>
    );
  }
}

// Higher-order component to inject the t function from useTranslation
const withTranslation = (Component) => (props) => {
  const { t } = useTranslation("survey_hub");
  return <Component {...props} t={t} />;
};

const TranslatedRadioButton = withTranslation(RadioButton);

export const registerSoSafeRadioButton = () => {
  ReactElementFactory.Instance.registerElement(SOSAFE_RADIO_BUTTON, (props) => (
    <TranslatedRadioButton {...props} />
  ));
};
