import React, { useEffect, useState } from "react";
import ContentPage from "flamingo-e-learning-platform/content/content.page";
import { useSoSafeConnect } from "shared/modules/sosafe-connect";
import useUser from "shared/hooks/use-user";
import { isUserAllowedOnPL } from "shared/hooks/use-is-user-allowed-pl";
import { GuardPage } from "flamingo-e-learning-platform/guard/guard.page";
import { useLocation } from "react-router";
import showHeader from "shared/helpers/personalizedLearning/show-header";
import ErrorPage from "shared/pages/error/error.page";
import useAuthentication from "authentication/hooks/use-authentication";
import FlamingoHeader from "../../../flamingo-e-learning-platform/header/header";

export const routes = [
  {
    path: "/",
    render: () => <GuardPage />,
  },
  {
    path: "/503",
    render: () => <ErrorPage errorCode={503} />,
  },
];

export const defaultRoute = "/";

export const Wrapper = ({ children }: { children: JSX.Element }) => {
  const { signOut } = useAuthentication();
  const { pathname } = useLocation();
  const enableHeader = showHeader(pathname);

  useEffect(() => {
    import("../../../theme/flamingo");
  }, []);

  return (
    <React.Fragment>
      {enableHeader && (
        <FlamingoHeader handleSignOutClick={signOut} minimizedView={true} />
      )}

      <ContentPage>{children}</ContentPage>
    </React.Fragment>
  );
};

export const UseIsPathAllowed = () => {
  const { apiKey } = useSoSafeConnect();
  const { user, loading } = useUser({});
  const isPersonalizedLearning = user?.campaign.isPersonalizedLearning ?? false;
  const loadingAuthorization = apiKey ? loading : false;
  const { isAllowed, isLoading } = isUserAllowedOnPL();

  return {
    allowed: !!apiKey && isPersonalizedLearning && !isAllowed,
    loading: loadingAuthorization && isLoading,
  };
};
