import React, { useState } from "react";
import {
  Button,
  Card,
  Progress,
} from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";
import { useTranslation } from "react-i18next";
import { useUserProgress } from "shared/hooks/use-user-progress";
import { Link } from "react-router-dom";
import useCertificate from "shared/hooks/use-certificate";
import {CertificateModal} from "shared/components/personalised-learning/certificate-modal";
import FLAGS from "../shared/check-for-flags";
import style from "./level-card.module.css";

interface LevelCardProps {
  badges: JSX.Element[];
  OnLearnMore: () => void;
}

export const LevelCard = ({ badges, OnLearnMore }: LevelCardProps) => {
  const { t } = useTranslation("flamingo");
  const { levelImage, levelName, progressBar } = useUserProgress();
  const { enabled: certificateEnabled } = useCertificate();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <Card withBorder radius={"lg"} className={style.card}>
      <div className={style.body}>
        <Link to="/achievements" className={style.title}>
          <div className={style.bodyElement}>
            {/* <button className={style.learnMore} onClick={OnLearnMore}>
            <small>{t("Learn more")}</small>
          </button> */}
            <h6 className={style.title}>{t("your_achievements")}</h6>
            <div className={style.levelBadge}>
              <img alt={t("level")} src={levelImage} />
            </div>
          </div>
        </Link>
        <div className={style.progressBarContainer}>

          <label className={style.progressBarHeader}>
            <h5 className={style.levelName}>{levelName}</h5>
          </label>
          <Progress
            role="progressbar"
            aria-valuenow={progressBar.curXp}
            aria-valuemin={0}
            aria-valuemax={progressBar.totalXp}
            aria-labelledby="progress-text"
            value={Math.floor(progressBar.progress)}
          />
          <label className={style.progreeBarLevelXp}>
            <strong>{progressBar.curXp}</strong>
            <span className="sr-only">{t("experiencePoints")}</span>
            <span>{t("out_of")}</span>
            <strong>{progressBar.totalXp}</strong>
            <span className="sr-only" >{t("experiencePoints")}</span>
          </label>
        </div>
        <div data-testid={"level-badges"} className={style.badges}>
          {badges?.map((BadgeElement) => BadgeElement)}
        </div>
        {FLAGS.isCertificateActivated && (
          <div>
            <div className={style.bodyElement}>
              <CertificateModal />
            </div>
          </div>
        )}
      </div>
    </Card>
  );
};
