import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { MODULE_FILTER_OPTIONS } from "flamingo-e-learning-platform/training/types/module-filter-options";
import { SegmentedControl } from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";
import style from "./module-filter.module.css";

interface ModuleFilterProps {
  onSelect: (options: MODULE_FILTER_OPTIONS) => void;
}

export const ModuleFilter = ({ onSelect }: ModuleFilterProps) => {
  const { t } = useTranslation("flamingo");

  const tagToggleContent = [
    { value: MODULE_FILTER_OPTIONS.ALL_LESSONS, label: t("all-lessons") },
    { value: MODULE_FILTER_OPTIONS.OVERDUE, label: t("overdue") },
    { value: MODULE_FILTER_OPTIONS.MANDATORY, label: t("mandatory") },
  ];

  const onToggleOption = (option: string) => {
    onSelect(option as MODULE_FILTER_OPTIONS);
  };

  return (
    <div className={style.container}>
      <SegmentedControl
        className={style.tag}
        data={tagToggleContent}
        onChange={onToggleOption}
        defaultValue={MODULE_FILTER_OPTIONS.ALL_LESSONS}
      />
    </div>
  );
};
