/**
 * @description - This Object contains the mapping of the question type to the custom component that will be used to render the question.
 * */
const customSoSafeItemComponentMap = {
  checkbox: "sosafe-checkbox-item",
};

/**
 * @description - This Object contains the default settings for the survey that fits SoSafe's requirements.
 * */
const SURVEY_DEFAULTS = {
  showPreviewBeforeComplete: "showAnsweredQuestions",
  showCompletedPage: false,
  questionsOnPageMode: "questionPerPage",
  showQuestionNumbers: "off",
  showProgressBar: "off",
  showNavigationButtons: false,
};

/**
 * @param {surveyJson} - The survey configuration object
 * @description - This function adds the property 'itemComponent' to the survey config depending on
 * the type of the question. This is used to render custom components for the survey questions.
 * */
export const addCustomItems = (surveyJson: any) => {
  const pages = surveyJson.pages.map((page) => {
    const customElements = page.elements.map((element) => ({
      ...element,
      itemComponent: customSoSafeItemComponentMap[element.type],
    }));

    return {
      ...page,
      elements: customElements,
    };
  });

  return {
    ...surveyJson,
    pages,
  };
};

/**
 * @param {surveyJson} - The survey configuration object
 * @description - This function removes the title property from the survey configuration object,
 * this makes sure that the title is not displayed in the survey.
 * */
export const sanitizeSurveyModel = (surveyJson: any) => {
  const pages = surveyJson.pages.map((page) => ({
    ...page,
    title: undefined,
  }));

  return {
    ...surveyJson,
    title: undefined,
    pages,
  };
};

/**
 * @param {surveyJson} - The survey configuration object
 * @description - This function adds the default settings for the survey that fits SoSafe's requirements.
 * */
export const attachSoSafeSurveyDefaults = (surveyJson: any) => ({
  ...surveyJson,
  ...SURVEY_DEFAULTS,
});

/**
 * @description - This function returns the custom css classes for the survey questions.
 * This is used to style the survey questions to fit SoSafe's requirements.
 * add here extra classes for the survey styles
 * */
export const customCssSurvey = () => ({
  root: "sosafe-surveys-root",
  body: "sosafe-surveys-body",
  header: "sosafe-surveys-header",
  content: "sosafe-surveys--stylescontent",
  container: "sosafe-surveys-container",
  page: {
    root: "sosafe-surveys-page",
  },
  panel: {
    title: "sosafe-surveys-panel-title",
  },
  question: {
    title: "sosafe-surveys-panel-title",
    mainRoot: "sosafe-surveys-question",
    withFrame: "sosafe-surveys-question-withframe",
    content: "sosafe-surveys-question-content",
    header: "sosafe-surveys-question-header",
    description: "sosafe-surveys-question-description",
  },
  radiogroup: {
    root: "sosafe-surveys-question-radiogroup",
    item: "sosafe-surveys-question-radiogroup-item",
    materialDecorator: "sosafe-surveys-radiogroup-materialdecorator",
    itemChecked: "sosafe-surveys-radiogroup-itemchecked",
    itemControl: "sosafe-surveys-radiogroup-itemcontrol",
    controlLabel: "sosafe-surveys-radiogroup-controllabel",
  },
  checkbox: {
    root: "sosafe-surveys-question-radiogroup",
  },
});

const isRightAnswer = (answers: string | string[], correctAnswer: string | string[]) => {
  if (typeof correctAnswer === "string") {
    return answers === correctAnswer;
  }

  if (Array.isArray(correctAnswer)) {
    return !correctAnswer.some((answer) => !answers.includes(answer));
  }

  return false;
};

export interface SurveySummaryResult {
  text: string;
  passed: boolean;
}

// We should use as survey type: `import { SurveyModel } from "survey";
// But unit test is failing when importing from survey
export const getSurveySummaryResult = (survey) => {
  const questions: Array<SurveySummaryResult> = [];

  survey.pages.forEach((page) => {
    const question = page.questions[0];
    if (!question) return;
    const answers = survey.getValue(question.name);
    const corectAnswer = question.correctAnswer;
    questions.push({
      text: question.title,
      passed: isRightAnswer(answers, corectAnswer),
    });
  });

  return questions;
};

export const isSurveyPassed = (result: Array<SurveySummaryResult>) => result.every((r) => r.passed);
