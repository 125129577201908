import React from "react";
import { Button } from "@sosafe-platform-engineering/fe-lib-ui-react";
import { useTranslation } from "react-i18next";
import styles from "./survey-welcome.module.css";

const SurveyWelcome = ({ onStart, title, description, SVG, startButtonText }) => {
  const { t } = useTranslation("survey_hub");
  return (
    <div className={styles.welcome}>
      <h5 className={styles.subHeading}>{title}</h5>
      <p className={styles.text}>{description}</p>
      <SVG className={styles.contentImage} />
      <div className={styles.actionBar}>
        <Button
          className={styles.button}
          size="large"
          ariaLabel={t("start")}
          onClick={() => onStart()}
        >
          {startButtonText}
        </Button>
      </div>
    </div>
  );
};

export default SurveyWelcome;
