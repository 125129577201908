import { useEffect } from 'react';
import chameleon from 'shared/modules/chameleon';
import { UserType } from 'types';

export default function useChameleon(user: UserType | undefined) {
  const userId = user?.address_uuid ?? user?.uuid;
  useEffect(() => {
    try {
      // only initialize once userId is defined
      if (!userId || !user) return;
      if (chameleon.isSupportedLanguage(user?.language || 'en')) {
        chameleon.identify(userId, {
          language: user?.language ?? window.navigator.language,
        });
      } else {
        chameleon.clear();
      }
    } catch (e) {
      console.warn('Failed to identify user with chameleon');
      console.warn(e);
    }
  }, [userId, user, user?.language]);
}
