import React, { useEffect } from "react";
import FaqPage from "support/pages/faq-page";
import CultureSurveyHubPage from "survey-hub/culture-survey-hub.page";
import ContentPage from "flamingo-e-learning-platform/content/content.page";
import { useSoSafeConnect } from "shared/modules/sosafe-connect";
import useUser from "shared/hooks/use-user";
import { useShouldShowSurvey } from "flamingo-e-learning-platform/hooks/use-should-show-survey";
import AccountPage from "flamingo-e-learning-platform/account/account";
import ErrorPage from "shared/pages/error/error.page";
import { useLocation } from "react-router-dom";
import showHeader from "shared/helpers/personalizedLearning/show-header";
import { SURVEY_TYPE } from "survey-hub/constants";
import { isUserAllowedOnPL } from "shared/hooks/use-is-user-allowed-pl";
import useAuthentication from "authentication/hooks/use-authentication";
import FlamingoHeader from "../../../flamingo-e-learning-platform/header/header";

export const routes = [
  {
    path: "/survey",
    render: () => <CultureSurveyHubPage />,
  },
  {
    path: "/account",
    render: () => <AccountPage />,
  },
  {
    path: "/faq",
    render: () => <FaqPage />,
  },
  {
    path: "/503",
    render: () => <ErrorPage errorCode={503} />,
  },
];

export const defaultRoute = "/survey";

export const Wrapper = ({ children }: { children: JSX.Element }) => {
  const { signOut } = useAuthentication();
  const { pathname } = useLocation();
  const enableHeader = showHeader(pathname);

  useEffect(() => {
    import("../../../theme/flamingo");
  }, []);

  return (
    <React.Fragment>
      {enableHeader && (
        <FlamingoHeader handleSignOutClick={signOut} minimizedView={true} />
      )}
      <ContentPage>{children}</ContentPage>
    </React.Fragment>
  );
};

export const UseIsPathAllowed = () => {
  const { apiKey } = useSoSafeConnect();
  const { user, loading } = useUser({});
  const { isAllowed, isLoading: isLoadingUserAllowed } = isUserAllowedOnPL();

  const isPersonalizedLearning = user?.campaign.isPersonalizedLearning ?? false;
  const loadingAuthorization = apiKey ? loading : false;

  const { showSurvey, loading: loadingShowSurvey } = useShouldShowSurvey({
    isPersonalizedLearning,
    surveyTypeId: SURVEY_TYPE.CULTURE_AND_CONTEXT,
    surveyFeatureId: 1,
  });

  const isLoadingSurveyData = isPersonalizedLearning
    ? loadingShowSurvey
    : false;

  return {
    allowed: !!apiKey && isPersonalizedLearning && showSurvey && isAllowed,
    loading: !!apiKey && (loadingAuthorization || isLoadingSurveyData) && isLoadingUserAllowed,
  };
};
