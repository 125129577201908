import { PersonalizedModule } from "flamingo-e-learning-platform/training/types/module.type";

export const personalizedModuleMock: PersonalizedModule = {
  id: 900,
  name: "Password manager",
  description: "<ul>     <li>How can a password manager help me?</li>     <li>How can I use a password manager?</li>     </ul>",
  category_id: 228,
  duration: 4,
  category: "Training",
  category_name: "Fast-Track Cyber Security Training",
  category_key: "training",
  finished_by: "2024-03-08T00:00:00.000Z",
  mandatory: true,
  xp: 50,
  progress: 0,
  status: '',
  moduleKey: 'manager',
  thumbnailUrl: 'https://resources.sosafe-stage.de/v1/resource/f88ff95f9b92703e/img/learningv3/training/manager.jpg',
  awarenessAssessmentIds: [],
}
