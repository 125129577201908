import { useQuery } from "@tanstack/react-query";
import { queryKey, useSoSafeConnect } from "shared/modules/sosafe-connect";
import useUser from "./use-user";

export const isUserAllowedOnPL = () => {
  const { apiKey, endPoints } = useSoSafeConnect();
  const { user } = useUser({});
  const isPersonalizedLearning = user?.campaign.isPersonalizedLearning ?? false;

  const { isSuccess, isLoading, refetch } = useQuery(
    [queryKey.IS_USER_READY_TO_USE_PL],
    () => endPoints.learningPathApi.fetch.isUserReadyToUsePL(user?.id || 0), {
    enabled: !!apiKey && isPersonalizedLearning && user !== undefined,
    staleTime: Infinity,
    refetchOnWindowFocus: true,
    retry: 1
  });

  return {
    isAllowed: isSuccess,
    isLoading,
    refetch
  }

}
